













































import {Component, Vue} from 'vue-property-decorator';
import {vxm} from '@/store';
import Vuelidate from 'vuelidate';
import {helpers, minLength, required, sameAs} from 'vuelidate/lib/validators';

Vue.use(Vuelidate);

@Component({
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(8),
        alpha: helpers.regex('alpha', /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/),
      },
      confirmPassword: {required, sameAs: sameAs('password')},
    },
  },
})
export default class ChangePassword extends Vue {
  showPass = true;
  showPass2 = true;
  form = {
    password: '',
    confirmPassword: '',
  };
  token = '';
  isConfirm = false;
  isError = false;
  isRequestSend = false;

  mounted() {
    this.token = this.$route.query.token as string;
    localStorage.setItem('agree', 'true');
  }

  changePassword() {
    if (!this.$v.form.$invalid) {
      this.isRequestSend = true;
      vxm.user
        .forgotChangePassword({
          password: this.form.password,
          rePassword: this.form.confirmPassword,
          token: this.token,
        })
        .then(() => {
          this.isConfirm = true;
        })
        .catch(() => {
          this.isError = true;
          this.$notify({
            group: 'foo',
            title: this.$t('error.wrong-confirm-link') as string,
            type: 'error',
            duration: 5000,
          });
        })
        .then(() => {
          this.isRequestSend = false;
        });
    }
  }
}
